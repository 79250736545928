<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'name',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="路径">
              <a-input
                v-decorator="[
                  'path',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="上级菜单">
              <a-cascader
                placeholder=""
                changeOnSelect
                v-decorator="[
                  'parentUuid',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                :options="menuList"
                :fieldNames="{
                  label: 'name',
                  value: 'key',
                  children: 'children',
                }"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="权限">
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                v-decorator="[
                  'permission',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-select-option
                  v-for="item in permissionList"
                  :key="item.code"
                  :value="item.code"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="类型">
              <a-radio-group
                v-decorator="[
                  'type',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-radio
                  v-for="item in menuTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="状态">
              <a-radio-group
                v-decorator="[
                  'status',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-radio
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="排序">
              <a-input-number
                style="width: 100%"
                v-decorator="[
                  'sort',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="图标">
              <a-input v-decorator="['icon']" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="图片">
              <FileUpload :multiple="false" @uploaded="uploaded">
                <a-button>{{ imagePath ? "重新上传" : "上传" }} </a-button>
              </FileUpload>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="分类">
              <a-input v-decorator="['category']" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="平台">
              <a-radio-group
                v-decorator="[
                  'platform',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-radio
                  v-for="item in [
                    { name: 'PC', value: 'pc' },
                    { name: 'App', value: 'app' },
                        { name: '党群系统', value: 'party' },
                  ]"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="描述"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="['remarks']"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { fetchList as fetchPermissionList } from "@/api/setting/permission";
import { fetchList as fetchMenuList, edit } from "@/api/setting/menu";
import { mapGetters } from "vuex";

import FileUpload from "@/components/file-upload";
export default {
  name: "editMenu",

  components: {
    FileUpload,
  },

  data() {
    return {
      form: this.$form.createForm(this),

      permissionList: [],
      menuList: [],
      pureMenuList: [], // 菜单原始数据

      uuid: null,

      imagePath: null,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    statusList() {
      return this.findDataDict("status");
    },
    menuTypeList() {
      return this.findDataDict("menu-type");
    },
  },

  activated() {
    fetchPermissionList({
      pageNum: 1,
      pageSize: 1000,
    }).then((res) => {
      if (Array.isArray(res.list)) {
        this.permissionList = res.list;
      }
    });

    this.getMenu().then(() => {
      const editingMenu = window.localStorage.getItem("editingMenu");

      if (editingMenu) {
        const {
          uuid,
          name,
          code,
          path,
          type,
          status,
          icon,
          parentUuid,
          permission,
          sort,
          remarks,
          platform,
          iconUrl,
          category,
        } = JSON.parse(editingMenu);

        console.log("edting menu ", editingMenu);
        this.imagePath = iconUrl;

        if (uuid !== this.uuid) {
          this.uuid = uuid;
          // 找到这个菜单它的父级菜单的uuid
          let uuids = [];
          this.findParentUuid(uuids, parentUuid);

          this.form.setFieldsValue({
            name,
            code,
            path,
            type,
            status: String(status),
            icon,
            parentUuid: uuids,
            permission,
            sort,
            remarks,
            platform,
            category,
          });
        }
      }
    });
  },

  methods: {
    getMenu() {
      return new Promise((resolve, reject) => {
        fetchMenuList()
          .then((res) => {
            if (Array.isArray(res)) {
              // const pureMenuList = res.filter((item) => item.type === "menu");
              const pureMenuList = res;

              pureMenuList.unshift({
                uuid: "0",
                key: "0",
                name: "根菜单",
                parentUuid: null,
              });

              this.pureMenuList = pureMenuList;

              this.menuList = this.filterMenu(
                pureMenuList.map((item) => {
                  item.key = item.uuid;
                  return item;
                }),
                null
              );

              resolve();
            } else {
              reject();
            }
          })
          .catch(() => {
            reject();
          });
      });
    },

    filterMenu(list, pid) {
      let tree = [];

      list.forEach((item) => {
        if (item.parentUuid === pid) {
          const children = this.filterMenu(list, item.uuid);

          const params = {
            ...item,
          };

          if (children && children.length > 0) {
            params.children = children;
          }

          tree.push(params);
        }
      });

      return tree;
    },

    uploaded(list) {
      if (list.length > 0) {
        this.imagePath = list[0].completePath;
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          edit({
            ...values,
            uuid: this.uuid,
            parentUuid: values.parentUuid[values.parentUuid.length - 1],
            iconUrl: this.imagePath,
          }).then(() => {});
        }
      });
    },

    findParentUuid(result, uuid) {
      const res = this.pureMenuList.find((item) => item.uuid === uuid);

      if (res) {
        result.unshift(res.uuid);
        this.findParentUuid(result, res.parentUuid);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
